import request from '@utils/request'
import { APIResult, Pages } from '@/typings'
import { exportFileAPI } from '@/api/file'
import settings from '@/settings'

/**
 * 根据条件获取报警
 * @param params
 */
export const getAlarmListAPI = (params?: {
  addressId?: number | string, deviceName?: string, beginDate?: string, endDate?: string,
} & Pages) => request.get('/alarm/show', { params })

/**
 * 删除报警记录
 */
export const deleteAlarmAPI = (params: { alarmIds: string }) => request.get('/alarm/remove', { params })

/**
 * 导出
 * @param params
 */
export const exportAlarmAPI = (params: {
  addressId: number | string, deviceName: string, beginDate: string, endDate: string,
}) => exportFileAPI('/alarm/excel', params, 'get')

/**
 * 根据设备和时间获取报警信息
 * @param params
 */
export const getAlarmListByDeviceIdAPI = (params?: {
  deviceId?: number, beginDate?: string, endDate?: string, date?: string
} & Pages): APIResult<APIResult.Payload<{ alarmId: number, createTime: string, describeribe: string }>> => request.get('/alarm/showyDeviceIdAndTime', { params })

/**
 * 导出
 * @param params
 */
export const exportAlarmByDeviceIdAPI = (params: {
  deviceId?: number, beginDate?: string, endDate?: string,
}) => exportFileAPI('/alarm/excelDeviceIdAndTime', params, 'get')

/**
 * 添加报警推送人
 * @param params
 */
export const addAlarmPushUserAPI = (params: {
  deviceIds: number[], smsAndWechat: {
    remark: string, code: string,
    // 1是微信, 2是短信
    smsOrVx: number
  }
}) => request.post('/smsAndWechat/addOrChange', params)

/**
 * 获取报警推送人列表
 * @param params
 */
export const getAlarmPushUserAPI = (params?: {
  code?: string,
  // 1是微信, 2是短信
  smsOrVx: number
}) => request.get('/smsAndWechat/show', { params })

/**
 * 删除推送人
 * @param params
 */
export const delAlarmPushUserAPI = (params?: {
  smsAndWechatId?: number
}) => request.get('/smsAndWechat/remove', { params })

/**
 * 获取微信报警二维码Ticket
 * @param params
 */
export const getCodeTicketAPI = (params?: {
  onlyMark: string
}) => request.get(settings.BASE_API2 + '/wxApi/getQRCode', { params })

export const getOpenIdByOnlyMarkAPI = (params?: {
  onlyMark: string
}) => request.get(settings.BASE_API2 + '/wxApi/getOpenId', { params })
