import request from '@/utils/request'
import { AxiosResponse } from 'axios'
import qs from 'qs'
import { User } from '@typings/user'

/**
 * 登录
 * @param params
 */
export const loginAPI = (params: any) => request.post('/sys/login', qs.stringify(params))

/**
 * 登出
 */
export const logoutAPI = () => request.post('/sys/logout')

/**
 * 获取用户信息
 */
export const getUserInfoAPI = () => request.get('/user/showHimself')
// 模拟获取用户
// export const getUserInfoAPI = () => {
//   const promise = new Promise(function (resolve) {
//     resolve({ data: { code: 200, payload: { data: { id: 1 } } } })
//   })
//   return <Promise<AxiosResponse<any>>>promise
// }

/**
 * 修改密码
 */
export const ModifyPwdForm = {
  password: '',
  newPassWord: '',
  confirmPassWord: ''
}
export type ModifyPwdFormType = typeof ModifyPwdForm
export const modifyPwdAPI = (params: ModifyPwdFormType) => request.post('/user/updatePassword', qs.stringify(params))

/**
 * 修改用户信息
 * @param params
 */
export const modifyUserInfoAPI = (params: User) => request.post('/user/updateByUserVo', params)

/**
 * 获取权限列表
 */
export const getAuthListAPI = () => request.get('/auth/list')
